// app/javascript/channels/room_channel.js
import consumer from "./consumer";
import $ from 'jquery';
import 'jquery-textcomplete';
import "emojionearea";

global.$ = global.jQuery = $;

const subManager = {
  current: {sub: undefined, id: undefined},

  set changeSub(newSub) {
    if (this.current.sub != undefined) {
      this.current.sub.unsubscribe();
    }
    this.current = newSub;
  },
  get getCurrent() {
    return this.current;
  }

}

$( document ).on('turbolinks:load', function() {
  var current_chat_room = $('#current_chat_room_id').text();

  if (current_chat_room != "") {
    createSubscriber(current_chat_room);
  }

  $('#chat_message_content').emojioneArea({
    events: {keydown: handleSend}
  })

  $('#chatrooms').on('click', '.chat-room', function() {
    selectChatRoom(this);
  })

  $(document).on('click', '#normal_send_button', function(){
    handleClickAirplane();
  })

  scrollChatDown();
});

function handleSend(editor, event) {
  if (event.keyCode === 13 && !event.shiftKey) {
    event.preventDefault();
    sendMessage(this.getText());
    this.setText('');
  }
}

function handleClickAirplane() {
  const editorEl = $('.emojionearea-editor');
  sendMessage(editorEl.text());
  editorEl.text('');
}

function sendMessage(message) {
  if (message == '') {return}
  subManager.getCurrent.sub.send({
    message: message,
    room: subManager.getCurrent.id
  })
}



function createSubscriber(chatRoomId) {
  console.log(`Creating Chat Room Subscriber for Room: ${chatRoomId}`);
  let newRoom = consumer.subscriptions.create({channel: "RoomChannel", chat_room_id: chatRoomId}, {
    connected() {
      // Called when the subscription is ready for use on the server
      console.log(`Connected to Chat Room: ${chatRoomId}`);
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
      $('#server-connection-lost').show();
    },

    received(data) {
      receiveData(data);
    }
  })

  subManager.changeSub = {sub: newRoom, id: chatRoomId};
}

function receiveData(data) {
  console.log(data);
  switch (data.type) {
  case 'message':
    receiveMessage(data);
    break;
  case 'chat_presence':
    receivePresence(data)
    break
  }
}

function receiveMessage(data) {
  let currentUserId = $('#current-user').data('current-user-id')
  if (currentUserId == data['senderId']) {
    $('#append_new_message').append(data['htmlSender']);
    $('#chat_message_content').val('');
  } else {
    $('#append_new_message').append(data['htmlReceiver']);
  }
  scrollChatDown();
}

function receivePresence(data) {
  let partnerId = $('.partner-box').data('user-id');

  switch (data.action) {
  case 'enter':
    if (partnerId == data.user_id) {
      $('.stream-status .presence').removeClass('d-none');
    }
    break;
  case 'leave':
    $('.stream-status .presence').addClass('d-none');
    break;
  }
}

function selectChatRoom(chatRoom) {

  let chatRoomId = $(chatRoom).data('id');
  console.log(chatRoomId);
  var AUTH_TOKEN = $('meta[name=csrf-token]').attr('content');
  $.ajax({
    headers: { 'X-CSRF-Token': AUTH_TOKEN },
    url: `/chat_rooms/${chatRoomId}`,
    type: 'get',
    success: function (result) {
      console.log('success');
      const previousRoom = $('.card-Onhover.active-card');
      previousRoom.find('.unread-notification-box').hide();
      $('.chat-room').removeClass('active-card');

      $('.card-Onhover').removeClass('active-card');
      $(chatRoom).addClass('active-card');

      createSubscriber(chatRoomId);

      $('#chat_message_content').emojioneArea({
        events: {keydown: handleSend}
      });
      $(chatRoom).find('.unread-notification-box').hide();
      scrollChatDown();
    },
    error: function () {
      $(".spinner-grow").addClass("d-none");
    }
  });
}

function scrollChatDown() {
  $('.coversationMessage-box').scrollTop(99999999);
}
